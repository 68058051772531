.tracto-registry-inspector-layers {
    &__table-item-digest {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-value {
            display: inline-block;
        }
    }

    &__table-item-digest_history {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    &__table-item-row_selected {
        background-color: rgba(153, 153, 153, 0.1);
    }

    &__table-item-command {
        width: 100%;
        max-width: 600px;
        padding: 6px;

        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--primary-text);
    }
}
