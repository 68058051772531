.jupyter-cell-overlay {
    &__show-it {
        cursor: pointer;
        font-weight: bold;
        color: var(--g-color-base-brand);
        &:hover {
            color: var(--g-color-base-brand-hover);
        }
    }
}
