@use '../../../styles/variables';

.jupyter-cell-content-layout {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;

    &__header {
        width: variables.$jupyter-cell-source-header-width;
    }

    &__content {
        margin: 0;
        box-sizing: border-box;
        flex: 1;
        overflow: hidden;
    }
}
