@use '../../styles/variables';

.jupyter-executable-cell-content {
    padding-top: 5px;
    margin: 0;
    justify-content: right;
    display: flex;
    height: 100%;
    color: var(--g-color-text-secondary);

    &_executed {
        color: inherit;
    }

    &_focused {
        color: var(--g-color-text-info);
    }

    &_editable {
        color: var(--g-color-text-info-heavy);
    }

    &_dirty {
        color: #f57c00;

        &:before {
            content: '•';
        }
    }
}
