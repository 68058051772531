.tracto-monaco-editor {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &__editor {
        flex-grow: 1;
        width: 100%;
        height: 100%;
    }

    & .margin:after {
        content: '';
        position: absolute;
        top: 0;
        right: 4px;
        width: 1px;
        background-color: var(--g-color-line-generic);
        height: 100%;
    }
}
