.blueprint-editor {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    &__editor {
        position: relative;
        min-height: 100%;

        & .monaco-editor {
            min-height: 100%;
        }
    }
}
