.jupyt-operation-page {
    min-width: 900px;
    padding: var(--section-main-padding-vertical) var(--section-main-padding-horizontal);

    &__header {
        display: flex;
        margin-top: 10px;
        align-items: center;

        margin-bottom: 20px;
    }

    &__error {
        padding-top: 20px;
    }

    &__operation-id {
        display: inline-block;
        min-width: 280px;
    }

    &__spacer {
        flex-grow: 1;
    }

    &__loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
