.workflow-graph-toolbar {
    width: 100%;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;

    &__save-button {
        min-width: 85px;
    }

    &__run-button {
        min-width: 72px;
    }
}
