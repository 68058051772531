.step-details-modal {
    &__section-header {
        display: block;
        margin-bottom: 14px;
    }

    &__section {
        margin-top: 28px;
        min-height: 80px;
    }

    &__section:first-child {
        margin-top: 0;
    }
}
