.execute-button {
    display: flex;
    align-items: center;

    &__status {
        margin-left: 8px;
        color: var(--g-color-text-secondary);
        overflow: hidden;
        white-space: nowrap;
        width: 80px;
        text-overflow: ellipsis;

        &_running {
            color: inherit;
        }

        &_executed {
            color: inherit;
        }
    }
}
