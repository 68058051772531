.secrets-list {
    &__secret-entry-container {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
    }

    &__secret-entry-row {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
    }

    &__secret-entry-left {
        flex-basis: 80px;
        margin-right: 10px;
    }

    &__secret-entry-right {
        flex-grow: 1;
        display: flex;
    }

    &__secret-entry-actions {
        min-width: 30px;
    }

    &__reveal-button {
        margin-left: 5px;
    }

    &__add-field-row {
        margin-top: 20px;
        width: calc(100% - 120px);
        margin-left: 90px;
        display: flex;
        justify-content: space-between;
    }

    &__add-field-row_no-secrets {
        margin-top: 0;
        width: 100%;
        margin-left: 0;
    }

    &__add-field-button {
        width: 100%;
    }
}
