.jupyt-speclet {
    &__edit {
        text-align: right;
        height: 0px;
    }

    &__raw-speclet {
        margin-top: -10px;
    }

    &__raw-speclet-error {
        margin-top: 40px;
    }

    &__dialog {
        .df-dialog {
            min-height: 400px;
        }
    }

    &__loader {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
