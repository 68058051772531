.workflow-step-component {
    --workflow-step-padding: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--light-background);

    &__details {
        position: absolute;
        right: 8px;
        bottom: 8px;
    }

    &__content {
        padding: var(--workflow-step-padding);
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        box-sizing: border-box;

        height: 32px;
        display: flex;
        gap: 6px;
        align-items: center;
        padding: 0 var(--workflow-step-padding);
        width: 100%;

        $header: #{&};

        &#{$header}_stage_done#{$header}_state_done {
            background: var(--g-color-base-positive-light);

            & > svg {
                color: var(--g-color-text-positive);
            }
        }

        &#{$header}_stage_done#{$header}_state_failed {
            background: var(--g-color-base-danger-light);

            & > svg {
                color: var(--g-color-text-danger);
            }
        }
    }

    &__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
