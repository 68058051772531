.orchestarcto-log {
    &__content {
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
    }

    &__loader-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__log-entry {
        padding-bottom: 8px;
    }
}
