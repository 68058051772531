@use '../../styles/variables';

.jupyter-cell-output {
    width: 100%;
    min-height: 30px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    padding: 0 0 0 110px;

    &__content {
        width: 100%;
        max-height: 90vh;
        overflow: auto;
        resize: vertical;
        border: 1px solid var(--g-color-line-generic);
        padding: 10px 0;
        position: relative;
    }

    &__copy {
        position: absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
    }

    &__row {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    &__output {
        width: 100%;
        margin: 0 3px;
        flex: 1;

        &_display {
            display: flex;
            flex-direction: row;
        }
    }
}
