.system {
    &__tracto-resources-heading {
        display: flex;
        gap: 0 8px;
        align-items: center;
    }

    &__tracto-resources-caption {
        display: flex;
        gap: 0 8px;
        align-items: center;
    }
}
