.tracto-registry-inspector-tree {
    &__node {
        cursor: pointer;
        display: flex;
        align-items: center;

        &_click:hover:after {
            background-color: var(--g-color-base-simple-hover);
        }

        &_click:after {
            content: '';
            position: absolute;
            inset: 0;
        }
    }

    &__icon {
        margin-right: 4px;
    }

    &__symlink {
        color: rgb(138, 138, 138);
    }

    &__tree-wrapper {
        height: 100%;
        position: sticky;
        top: 0;
        padding-top: 60px;
    }

    .elements-table_size_s .elements-table__body .elements-table__row {
        position: relative;
        height: 26px;
    }

    & .elements-table__cell {
        &:nth-child(3) {
            width: 120px;
        }

        &:last-child {
            width: 550px;
        }
    }
}
