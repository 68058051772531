.jupyter-grid {
    &__grid {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__grid-item {
        z-index: 100;
        display: flex;
        align-content: center;
    }

    &__grid-item:hover {
        .jupyter-grid__drag-handle {
            visibility: visible;
        }
    }

    &__grid-separator {
        padding-left: 19px;
    }

    &__drag-handle {
        margin-right: 6px;
        cursor: grab;
        user-select: none;
        visibility: hidden;
    }

    &__placeholder {
        margin-top: 18px;
        padding-left: 19px;
    }
}

.grabbing {
    cursor: grabbing;
}

.grabbing * {
    cursor: grabbing;
}
