$jupyter-cell-source-header-width: 60px;

.g-root_theme_dark,
.g-root_theme_dark-hc {
    --yt-jupyter-cell-background: #181818;
}

.g-root_theme_light,
.g-root_theme_light-hc {
    --yt-jupyter-cell-background: #f0f0f0;
}
