.tracto-registry-inspector-tab {
    height: 100%;

    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 10vh;
        width: 100%;
    }

    &__tree-wrapper {
        height: 100%;
        width: 100%;
        position: sticky;
        top: 0;
        padding-top: 60px;
    }

    &__refresh {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
