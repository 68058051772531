.jupyt-secrets-tab {
    &__loader {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__secrets-map-wrapper {
        max-width: 720px;
    }

    &__editor-wrapper {
        margin-bottom: 20px;
    }
}
