.jupyter-cell-layout {
    outline: none;
    border: 1px solid var(--g-color-line-generic);
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    position: relative;

    &_executed {
        border: 1px solid var(--g-color-line-generic-active);
    }

    &_focused {
        border: 1px solid var(--g-color-line-info);
    }

    &_editable {
        border: 1px solid var(--g-color-text-info-heavy);
    }
}
