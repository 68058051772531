.blueprint-image-params {
    &__build-param-row {
        margin-top: 10px;
        display: flex;
    }

    &__build-param-row-label {
        flex-basis: 100px;
        margin-right: 10px;
    }
}
