.button-with-hotkey {
    &__tooltip-content {
        --_--padding: 0px;
    }

    &__popover-content {
        height: 40px;
        padding: 1px;
        scale: 0.9;
    }
}
