.attached-files-list {
    &__attach-entry-container {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
    }

    &__attach-entry-row {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
    }

    &__attach-entry-left {
        flex-basis: 95px;
        margin-right: 10px;
    }

    &__attach-entry-right {
        flex-grow: 1;
        display: flex;
    }

    &__add-entry-row {
        margin-top: 20px;
        width: calc(100% - 135px);
        margin-left: 105px;
        display: flex;
        justify-content: space-between;
    }

    &__add-entry-row_no-entires {
        margin-top: 0;
        width: 100%;
        margin-left: 0;
    }

    &__add-entry-button {
        width: 100%;
    }

    &__attach-entry-actions {
        min-width: 30px;
    }

    &__entry-path-editor {
        width: 100%;
    }
}
