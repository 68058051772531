.workflow-runs-list {
    width: 100%;
    overflow: hidden;

    &__range-picker {
        max-width: 350px;
        flex: 1 1 0;
    }

    &__limit-input {
        max-width: 150px;
        flex: 1 1 0;
    }

    &__labels-select {
        flex: 1 1 0;
    }

    &__row_selected {
        background-color: var(--g-color-base-simple-hover-solid);
    }

    &__table {
        & table {
            display: table;
            width: 100%;
        }
        overflow: scroll;
        height: 100%;
    }

    &__labels-cell {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
