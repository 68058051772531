.dialog-hotkeys-preview {
    width: 65vw;
    height: 75vh;

    &__body {
        margin-top: 54px;
    }

    &__table {
        & table {
            display: table;
            width: 100%;
        }
    }
}
