.markdown-cell-source {
    &__markdown-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        &_hidden {
            display: none;
        }
    }

    &__markdown-container {
        width: 100%;
        padding: 5px 5px 5px 55px;
        font-family: Menlo, Monaco, 'Courier New', monospace;
        font-size: 14px;
        line-height: 21px;

        & > :first-child {
            margin-block-start: 0;
        }

        & > :last-child {
            margin-block-end: 0;
        }

        & a.yfm-anchor {
            display: none;
        }
    }

    &__markdown-editor-wrapper {
        &_hidden {
            display: none;
        }
    }
}
