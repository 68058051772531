.tracto-registry-tab {
    &__split {
        overflow: initial;
    }

    &__section {
        flex: 1 1 100%;
        min-width: 800px;
    }

    &__wrapper {
        gap: 64px;
        margin-top: 4px;
    }

    &__split-pane-item {
        width: 100%;
    }

    &__close-btn {
        position: absolute;
        right: 0;
    }

    &__description {
        &-inner {
            position: sticky;
            top: 60px;
        }
    }
}
