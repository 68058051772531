.jupyt-select-control {
    &__control {
        display: flex;
        align-items: center;

        & .yc-button__text {
            width: 100%;
        }
    }

    &__icon {
        margin-left: 10px;
        min-width: 16px;
    }

    &__option {
        width: 250px;
        max-width: 250px;
    }
}
