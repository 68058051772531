.resources-type-switch {
    font-weight: 600;
    letter-spacing: 0em;
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;

    &__radio-button {
        margin-left: 20px;
    }
}
