.elements-error-details {
    &__details {
        display: flex;
        flex-direction: column;
        gap: 10px;

        padding: 8px;
        margin-bottom: 10px;
        margin-top: 4px;;

        border-radius: var(--g-border-radius-l);

        background-color: var(--light-background);
    }

    &__message {
        white-space: pre-wrap;
    }

    &__error {
        margin-bottom: 5px;

        &-toggler {
            margin-right: 4px;
        }
    }

    &__error:last-child,
    &__error:last-child &__details {
        margin-bottom: 0;
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
            color: var(--g-color-text-complementary);
            font-weight: 700;
        }

        ::before {
            display: none;
        }
    }

    &__inner-errors {
        margin: 0;
        padding: 0 0 0 20px;
    }
}
