.tracto-registry-tag-description {
    &__header {
        display: flex;
        align-items: center;
    }

    &__env {
        overflow: hidden;
        &-item {
            overflow-wrap: break-word;
            line-break: anywhere;
        }
    }

    &__dockerfile {
        margin: 0 0 0 12px;
    }

    &__elements-section {
        margin-bottom: 8px;
    }
}
