.tracto-registry-image-layers {
    &__table-item-digest {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-value {
            display: inline-block;
        }
    }
}
