.tracto-file-picker {
    cursor: pointer;
    position: relative;

    &__input {
        visibility: hidden;
        position: absolute;
        z-index: -1;
        top: 0;
    }
}
