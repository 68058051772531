.tracto-secret-store-tab {
    &__toolbar {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__split-pane {
        margin-top: 20px;
    }

    &__split-pane-item {
        width: 100%;
    }

    &__save-button {
        width: 85px;
    }
}
