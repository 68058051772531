.jupyter-ansi {
    --g-color-ansi-cian: rgb(0, 205, 205);

    .ansi-black-fg {
        color: var(--g-color-text-primary);
    }

    .ansi-red-fg {
        color: var(--g-color-text-danger);
    }

    .ansi-green-fg {
        color: var(--g-color-text-positive);
    }

    .ansi-yellow-fg {
        color: var(--g-color-text-warning);
    }

    .ansi-blue-fg {
        color: var(--g-color-text-info);
    }

    .ansi-magenta-fg {
        color: var(--g-color-text-utility);
    }

    .ansi-cyan-fg {
        color: var(--g-color-ansi-cian);
    }

    .ansi-white-fg {
        color: var(--g-color-text-light-primary);
    }

    .ansi-black-bg {
        background-color: var(--g-color-base-misc-heavy);
    }

    .ansi-red-bg {
        background-color: var(--g-color-base-danger-heavy);
    }

    .ansi-green-bg {
        background-color: var(--g-color-base-positive-heavy);
    }

    .ansi-yellow-bg {
        background-color: var(--g-color-base-warning-heavy);
    }

    .ansi-blue-bg {
        background-color: var(--g-color-base-info-heavy);
    }

    .ansi-magenta-bg {
        background-color: var(--g-color-base-utility-heavy);
    }

    .ansi-cyan-bg {
        background-color: var(--g-color-ansi-cian);
    }

    .ansi-white-bg {
        background-color: var(--g-color-base-light);
    }
}
