.tracto-registry-tags-list {
    &__tags {
        width: 100%;
        max-width: 50vw;
    }

    &__tag {
        padding: 12px;
        margin-bottom: 16px;
        border-radius: 0;
    }

    &__row-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-bottom: 12px;

        &-created {
            margin-top: 8px;
            color: var(--g-color-text-secondary);
        }
    }

    &__row-content {
        margin: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
