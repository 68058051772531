.monitoring-markdown {
    padding: 0 10px;
    display: grid;
    align-items: center;
    overflow: scroll;
    height: 100%;

    & {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }
    }
}
