.delegations-list {
    &__table {
        min-height: 720px;
    }

    &__pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        min-width: 560px;
    }
}
