@use '../../styles/variables';

.notebook-drop-area {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--g-color-line-generic);
    border-radius: 4px;
    background-color: var(--yt-jupyter-cell-background);
    cursor: pointer;

    &__title {
        font-family: Menlo, Monaco, 'Courier New', monospace;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }

    &__icon {
        margin-top: 20px;
    }
}
