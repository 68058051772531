.notebook-placeholder {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;

    &__option {
        width: calc(50% - 25px);
        height: 100%;
    }
}
