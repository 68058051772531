.jupyter-cell-controls-layout {
    --controls-height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 3px 3px 3px 60px;

    width: 100%;

    &__left-controls {
        display: flex;
        gap: 10px;
        height: var(--controls-height);
    }

    &__right-controls {
        display: flex;
        gap: 10px;
        height: var(--controls-height);
    }
}
