.workflow-execution-graph-toolbar {
    width: 100%;
    justify-content: space-between;
    background-color: var(--g-color-base-background);
    padding-left: 20px;
    min-height: 40px;
    display: flex;
    align-items: center;

    &__restart-all {
        min-width: 125px;
    }

    &__right-section {
        display: flex;
        gap: 8px;
    }

    &__left-section {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
    }
}
